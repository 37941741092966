// Generated by Framer (70e732d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["T1twWuQQO", "ySdyfhA4m"];

const variantClassNames = {T1twWuQQO: "framer-v-kmhqgr", ySdyfhA4m: "framer-v-yo3p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "T1twWuQQO", "Variant 2": "ySdyfhA4m"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "T1twWuQQO", image: n4qxtOqDv = {src: new URL("assets/1024/7fujnJvMrgoM2FC6eSj6TZ1JUe0.png", import.meta.url).href, srcSet: `${new URL("assets/512/7fujnJvMrgoM2FC6eSj6TZ1JUe0.png", import.meta.url).href} 394w, ${new URL("assets/1024/7fujnJvMrgoM2FC6eSj6TZ1JUe0.png", import.meta.url).href} 789w, ${new URL("assets/7fujnJvMrgoM2FC6eSj6TZ1JUe0.png", import.meta.url).href} 1280w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "T1twWuQQO", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RZ3K0", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 1661, intrinsicWidth: 1280, pixelHeight: 1661, pixelWidth: 1280, sizes: "544px", ...toResponsiveImage(n4qxtOqDv)}} className={cx("framer-kmhqgr", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"T1twWuQQO"} ref={ref} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} transition={transition} {...addPropertyOverrides({ySdyfhA4m: {"data-framer-name": "Variant 2", background: {alt: "", fit: "fill", intrinsicHeight: 1120, intrinsicWidth: 1680, pixelHeight: 1120, pixelWidth: 1680, sizes: "544px", src: new URL("assets/3CNTMPbbvGheZMtmr1fp2GMKU64.png", import.meta.url).href, srcSet: `${new URL("assets/512/3CNTMPbbvGheZMtmr1fp2GMKU64.png", import.meta.url).href} 512w, ${new URL("assets/1024/3CNTMPbbvGheZMtmr1fp2GMKU64.png", import.meta.url).href} 1024w, ${new URL("assets/3CNTMPbbvGheZMtmr1fp2GMKU64.png", import.meta.url).href} 1680w`}}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RZ3K0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RZ3K0 .framer-lvvnbq { display: block; }", ".framer-RZ3K0 .framer-kmhqgr { height: 555px; overflow: visible; position: relative; width: 544px; }", ".framer-RZ3K0.framer-v-yo3p .framer-kmhqgr { height: 650px; max-width: 880px; min-height: 650px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 555
 * @framerIntrinsicWidth 544
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ySdyfhA4m":{"layout":["fixed","fixed"],"constraints":[null,"880px","650px",null]}}}
 * @framerVariables {"n4qxtOqDv":"image"}
 */
const FramergL2_ZSz7B: React.ComponentType<Props> = withCSS(Component, css, "framer-RZ3K0") as typeof Component;
export default FramergL2_ZSz7B;

FramergL2_ZSz7B.displayName = "Work - List";

FramergL2_ZSz7B.defaultProps = {height: 555, width: 544};

addPropertyControls(FramergL2_ZSz7B, {variant: {options: ["T1twWuQQO", "ySdyfhA4m"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, n4qxtOqDv: {__defaultAssetReference: "data:framer/asset-reference,7fujnJvMrgoM2FC6eSj6TZ1JUe0.png?originalFilename=b-015.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramergL2_ZSz7B, [])